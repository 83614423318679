import React from "react";
import AppRoutes from "../AppRoutes";
import Footer from "./Navigation/Footer";
import NavBar from "./Navigation/NavBar";

export default function Layout() {
    return (
        <>
            <NavBar />
            <AppRoutes />
            <Footer/>
        </>
    );
}

