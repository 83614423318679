/* eslint-disable no-unused-vars */

export const White = "#FFFFFF";
export const Black = "#000000";

export const Blue1 = "#8DCFF0";
export const Blue1b = "#5ADCED";
export const Blue2 = "#5ABCED";
export const Blue3 = "#1498D8";
export const Blue4 = "#0575AD";
export const Blue5 = "#004E75";

export const Grey1 = "#FFFFFF";
export const Grey1b = "#F7F9FC";
export const Grey2 = "#EDEFF3";
export const Grey3 = "#D8DDE4";
export const Grey4 = "#BAC3CE";
export const Grey5 = "#A8A9AF";
export const Grey6 = "#7B8291";
export const Grey6b = "#757575";
export const Grey7 = "#525968";
export const Grey8 = "#262837";
export const Grey9 = "#757575";
export const Grey10 = "#F7F9FC";
export const Grey17 = "#1F2430";

export const Red1 = "#F096Ad";
export const Red2 = "#EC6759";
export const Red3 = "#E74C3C";
export const Red4 = "#BD2930";
export const Red5 = "#730E03";

export const Yellow1 = "#FDE776";
export const Yellow2 = "#FDDC3F";
export const Yellow3 = "#F1C40F";
export const Yellow4 = "#BC932C";
export const Yellow5 = "#876D27";

export const Green1 = "#98EDBB";
export const Green2 = "#61E097";
export const Green3 = "#00D65B";
export const Green4 = "#00A044";
export const Green5 = "#006a2D";

export default function styles() {
    return ({
        basicButton: {
            color: "#FFFFFF",
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: Blue3,
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Blue2,
            },
            "&:active": {
                backgroundColor: Blue5,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        secondaryButton: {
            color: Grey8,
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: "#FFFFFF",
            height: "36px",
            minWidth: "136px",
            borderStyle: "solid",
            borderColor: Grey4,
            borderWidth: "1px",
            "&:hover:enabled": {
                borderStyle: "solid",
                borderColor: Blue2,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                color: Blue2,
            },
            "&:active": {
                borderStyle: "solid",
                borderColor: Blue3,
                borderWidth: "1px",
                color: Blue4,
            },
            "&:disabled": {
                color: Grey3,
                borderStyle: "solid",
                borderColor: Grey3,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                borderStyle: "solid",
                borderColor: Grey3,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                cursor: "not-allowed",
            },
        },
        greenButton: {
            color: "#FFFFFF",
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: Green3,
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Green2,
            },
            "&:active": {
                backgroundColor: Green4,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        yellowButton: {
            color: Grey8,
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: Yellow3,
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Yellow2,
            },
            "&:active": {
                backgroundColor: Yellow4,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        redButton: {
            color: "#FFFFFF",
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: Red3,
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Red2,
            },
            "&:active": {
                backgroundColor: Red4,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        redOutlinedButton: {
            color: Red3,
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            borderColor: Red3,
            backgroundColor: "#FFFFFF",
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Red2,
                color: "#FFFFFF",
            },
            "&:active": {
                backgroundColor: Red4,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        textButton: {
            color: Blue3,
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            backgroundColor: "#FFFFFF",
            height: "36px",
            minWidth: "136px",
            border: "none",
            "&:hover:enabled": {
                color: Blue2,
                backgroundColor: "#FFFFFF",
            },
            "&:active": {
                color: Blue4,
                backgroundColor: "#FFFFFF",
            },
            "&:disabled": {
                color: Grey2,
                backgroundColor: "#FFFFFF",
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey2,
                backgroundColor: "#FFFFFF",
                cursor: "not-allowed",
            },
        },
        assistiveText: {
            fontSize: "12px",
            color: Grey6,
        },
        selectPage: {
            fontSize: 13,
            marginLeft: 2,
            color: Grey7,
            fontWeight: 600,
            textAlign: "center",
            "& svg": {
                width: 18,
                height: 18,
                marginTop: 3,
                marginRight: 2,
                color: Blue3,
            },
            "& .MuiInput-input": {
                padding: "8px 18px 8px 8px",
            },
        },
        multiSelect: {
            "& legend": {
                visibility: "visible",
                "& span": {
                    backgroundColor: "#FFFFFF",
                    transform: "translate(2px, -6px)",
                    position: "fixed",
                    color: Grey6b,
                },
            },
        },
        tableRow: {
            "& .edit-svg": {
                visibility: "hidden",
            },
            "&.MuiTableRow-hover:hover": {
                "& .edit-svg": {
                    visibility: "visible",
                },
            },
        },
        tableHeader: {
            fontSize: "14px",
            fontWeight: 500,
            color: Grey6,
            border: 0,
        },
        tableCell: {
            fontSize: "14px",
            fontWeight: 400,
            color: Grey8,
            border: 0,
        },
        longLabel: {
            width: 310,
        },
        containerMargin: {
            paddingLeft: "30px",
            paddingRight: "30px",
        },
        containerMargin2: {
            paddingLeft: "0px",
            paddingRight: "0px",
        },
        AppletHeaderControlsLeft: {
            fontSize: "14pt",
            fontWeight: "500",
            color: "#1498D8",
            margin: "-5px 0 0 0",
            overflow: "hidden",
        },
        AppletHeaderContainerFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "space-between",
        },
        AppletHeaderContainerEmptyFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "flex-start",
        },
        AppletFooterContainerFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "30px",
            paddingTop: "3px",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "flex-start",
            background: "#FFFFFF",
        },
        AppletFooterContainerNonFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "flex-start",
            backgroundColor: "#FFFFFF",
        },
        AppletHeaderContainerNonFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "flex-start",
            backgroundColor: "#FFFFFF",
        },
        AppletHeaderContainerEmptyNonFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "flex-start",
            backgroundColor: "#FFFFFF",
        },
        TableListFocus: {
            display: "block",
            width: "100%",
            height: "100%",
            overflow: "scroll",
        },
        TableListNonFocus: {
            display: "block",
            width: "100%",
            height: "100%",
            overflow: "scroll",
        },
        DetailBodyContentFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingTop: "10px",
        },
        DetailBodyContentNonFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingTop: "10px",
        },
        DetailHeaderControlsLeft: {
            fontSize: "14pt",
            color: "#888888",
            width: "300px",
            margin: "4px 0 0 10px",
            overflow: "hidden",
        },
        AppletDetailFooterContainerFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "5px",
            borderLeft: "2px solid #AAAAAA",
            borderRight: "2px solid #AAAAAA",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "flex-start",
            borderBottom: "2px solid #AAAAAA",
            borderRadius: "0 0 5px 5px",
            background: "#FFFFFF",
        },
        CloudcathDetail: {
            display: "block",
            width: "100%",
            height: "100%",
        },
        DetailContent: {
            display: "block",
            width: "100%",
            height: "100%",
        },
        DetailToolbarContentFocus: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "33px",
            borderLeft: "2px solid #AAAAAA",
            borderRight: "2px solid #AAAAAA",
            padding: "12px 0 10px 0",
            borderBottom: "1px solid #DDDDDD",
        },
        DetailHeaderControlsMenu: {
            fontSize: "10pt",
            color: "#FFFFFF",
            width: "300px",
            margin: "1px 0 0 10px",
        },
        DetailHeaderControlsYearMenu: {
            fontSize: "10pt",
            color: "#FFFFFF",
            width: "100px",
            margin: "1px 0 0 10px",
        },
        DetailHeaderControlsMonthMenu: {
            fontSize: "10pt",
            color: "#FFFFFF",
            width: "100px",
            margin: "1px 0 0 10px",
        },
    });
}