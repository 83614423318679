import {formatIncompletePhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import moment from "moment-timezone";

export const Phone = (value) => {
    if (!value) return null;
    try {
        const phoneNumber = parsePhoneNumber(value, "US");
        return phoneNumber.formatNational();
    } catch (e) {
        return value;
    }
};

export const PhoneIncomplete = (value) => {
    if (!value) return null;
    return formatIncompletePhoneNumber(value, "US");
};

export const EmailWithEllipsis = (value, maxLength) => {
    if (!value) return null;
    if (value.length <= maxLength) return value;

    const parts = value.split("@");
    const partLength = Math.floor(maxLength / 2);
    const leftLength = Math.min(parts[0].length, partLength);
    const rightLength = maxLength - leftLength;

    if (parts[0].length > leftLength) {
        parts[0] = `${parts[0].slice(0, leftLength)}\u2026`;
    }

    if (parts[1].length > rightLength) {
        parts[1] = `${parts[1].slice(0, rightLength)}\u2026`;
    }

    return parts[0] + "@" + parts[1];
};



export const ISO_8601 = "YYYY-MM-DD[T]HH:mm:ss.000[Z]";

export const updateObject = (a, b) => ({...a, ...b});

export const formatLocalDate = (date, format) => {
    if (date === undefined || date === null) return null;
    if (typeof date === "string") date = moment.utc(date);
    return moment(date).local().format(format ?? "MMM DD, YYYY");
};

export const formatDate = (date, format) => {
    if (date === undefined || date === null) return null;
    if (typeof date === "string") date = moment.utc(date);
    return moment(date).format(format ?? "MMM DD, YYYY");
};

export const formatDateAbbreviated = (date, format) => {
    if (date === undefined || date === null) return null;
    if (typeof date === "string") date = moment.utc(date);
    return moment(date).format(format ?? "MMM DD");
};

export const formatLocalDateAbbreviated = (date, format) => {
    if (date === undefined || date === null) return null;
    if (typeof date === "string") date = moment.utc(date);
    return moment(date).local().format(format ?? "MMM DD");
};

export const formatLocal = (datetime, format) => {
    if (datetime === undefined || datetime === null) return null;
    if (typeof datetime === "string") datetime = moment.utc(datetime);
    let tz = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("z");
    return moment(datetime).local().format(format ?? "YYYY-MM-DD hh:mm:ss A") + " " + tz;
};

export const convertToUTCDatePlusCurrentTime = (dateToConvert) => {
    if (dateToConvert === null || dateToConvert === "") return null;

    let moment = require("moment");

    let currentDateTime = moment();

    dateToConvert.set({
        hour: currentDateTime.get("hour"),
        minute: currentDateTime.get("minute"),
        second: currentDateTime.get("second"),
    });

    return moment(dateToConvert).utc().format("YYYY-MM-DD HH:mm:ss");
};

export const getUTCDatePlusCurrentTime = () => {
    let moment = require("moment");

    let currentDateTime = moment();

    return moment(currentDateTime).utc().format("YYYY-MM-DD HH:mm:ss");
};

export const formatWithoutSeconds = (datetime) => {
    if (datetime === undefined || datetime === null) return null;
    if (typeof datetime === "string") datetime = moment.utc(datetime);
    return moment(datetime).format("YYYY-MM-DD hh:mm A");
};

export const formatLocalWithoutSeconds = (datetime) => {
    if (datetime === undefined || datetime === null) return null;
    if (typeof datetime === "string") datetime = moment.utc(datetime);
    let tz = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("z");
    return moment(datetime).local().format("YYYY-MM-DD hh:mm A") + " " + tz;
};

export const formatUtcDate = (date) => {
    if (date === undefined || date === null) return null;
    if (typeof date === "string") date = moment.utc(date);
    return moment(date).utc().format("YYYY-MM-DD");
};

export const formatUTC = (datetime) => {
    if (datetime === undefined || datetime === null) return null;
    if (typeof datetime === "string") datetime = moment.utc(datetime);
    return moment(datetime).utc().format("YYYY-MM-DD HH:mm:ss");
};
