import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import {Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import css_self from "../../pages/css/Pages.module.scss";
import {useNavigate} from "react-router-dom";


export default function Reference() {
    const navigate = useNavigate();

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Reference</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Reference topics are especially useful if you're new to REST APIs. You can start by reviewing commonly used REST API terms, the different types of REST resources, such as singular and collection resources,
                and resource REST API Versions and URL paths. Explore the following topics:</p>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450, border: "2px solid #EEEEEE"}}>
                    <TableHead>
                        <TableRow style={{height: "40px", backgroundColor: "#FAFAFA", borderRight: "1px solid #FAFAFA"}}>
                            <TableCell size='small' width={"50%"} style={{borderRight: "2px solid #EEEEEE", color: "#000000"}}><b>Topic</b></TableCell>
                            <TableCell size='small' width={"50%"} align="left"><b>Description</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}><Link className={css_self.Link2} onClick={(event) => {navigate("/apidocstools/reference/httpheaders");}}>HTTP Headers</Link></TableCell>
                            <TableCell size='small' align="left">List of supported HTTP headers</TableCell>
                        </TableRow>
                        <TableRow key={2} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}><Link className={css_self.Link2} onClick={(event) => {navigate("/apidocstools/reference/restresourcetypes");}}>REST Resource Types</Link></TableCell>
                            <TableCell size='small' align="left">Parameters and properties of the two types of resources: singular and collection</TableCell>
                        </TableRow>
                        <TableRow key={3} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}><Link className={css_self.Link2} onClick={(event) => {navigate("/apidocstools/reference/statuscodes");}}>Status Codes</Link></TableCell>
                            <TableCell size='small' align="left">List of JavaScript Object Notation (JSON) resource attribute types</TableCell>
                        </TableRow>
                        <TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}><Link className={css_self.Link2} onClick={(event) => {navigate("/apidocstools/reference/statuscodes");}}>Supported Data Types</Link></TableCell>
                            <TableCell size='small' align="left">List of standard HTTP response codes</TableCell>
                        </TableRow>
                        <TableRow key={5} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}><Link className={css_self.Link2} onClick={(event) => {navigate("/apidocstools/reference/supportedhttpmethods");}}>Supported HTTP Methods</Link></TableCell>
                            <TableCell size='small' align="left">Methods <span style={{fontFamily: "courier"}}>GET, POST, PATCH, </span>and <span style={{fontFamily: "courier"}}>DELETE</span> and which work with each data type</TableCell>
                        </TableRow>
                        <TableRow key={6} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}><Link className={css_self.Link2} onClick={(event) => {navigate("/apidocstools/reference/supportedmediatypes");}}>Supported Media Types</Link></TableCell>
                            <TableCell size='small' align="left">List of data formats for HTTP payloads</TableCell>
                        </TableRow>
                        <TableRow key={7} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}><Link className={css_self.Link2} onClick={(event) => {navigate("/apidocstools/reference/terminology");}}>Terminology</Link></TableCell>
                            <TableCell size='small' align="left">List of commonly used terms</TableCell>
                        </TableRow>
                        <TableRow key={8} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}><Link className={css_self.Link2} onClick={(event) => {navigate("/apidocstools/reference/urlpaths");}}>URL Paths</Link></TableCell>
                            <TableCell size='small' align="left">Variations of resource paths across product versions</TableCell>
                        </TableRow>
                        <TableRow key={9} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>Use JSON Web Token for Authorization</TableCell>
                            <TableCell size='small' align="left">JSON Web Token (JWT) is a compact token format that lets you authorize yourself</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}