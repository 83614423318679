import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import EventBus from "../../util/EventBus";

export default function Logout() {

    const navigate = useNavigate();

    useEffect(() => {

        sessionStorage.clear();

        // @ts-ignore
        EventBus.session.emit("update");

        navigate("/", {replace: true});

    }, [navigate]);

    return null;
}
