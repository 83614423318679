import React from "react";
import css_self from "./css/Footer.module.scss";
import enlilImage from "../../assets/logo-5cd08655.svg";

export default function Footer() {

    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
            <div className={css_self.Footer}>
                <div style={{fontWeight: 600, fontSize: "50px", color: "#FFFFFF", height: "150px", textAlign: "center"}}>
                    Ready to elevate your business with Enlil?
                </div>
            </div>
            <div style={{padding: "30px 30px 20px 30px"}}>
                <div style={{paddingTop: "0px", paddingRight: "2px", marginLeft: "-5px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
            </div>
        </div>
    );
}

